import React, { FC } from 'react';
import { OverlappingCard } from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../../settingsParams';
import { useInvertInfoPosition } from '../../../hooks/useInvertInfoPosition';
import { ServiceLayoutProps } from '../ServiceCard';
import { DataHooks } from '../consts';
import { classes, st } from './Overlapping.st.css';

export const Overlapping: FC<ServiceLayoutProps> = (props) => {
  const { image, info, index } = props;
  const invertInfoPosition = useInvertInfoPosition();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const shouldAlternate =
    experiments.enabled('specs.Bookings.ImproveServiceListLayoutSettings') &&
    settings.get(settingsParams.alternateImageInfoSides) &&
    (index ?? 0) % 2 === 1;

  return (
    <OverlappingCard
      data-hook={DataHooks.OVERLAPPING}
      media={image}
      info={info}
      className={st(classes.root, {
        hasImage: Boolean(image),
        invert: invertInfoPosition,
        useCornerRadius: experiments.enabled(
          'specs.bookings.AddCardCornerRadius',
        ),
      })}
      invertInfoPosition={invertInfoPosition || shouldAlternate}
    />
  );
};
